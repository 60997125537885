@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Lato&family=Poppins:wght@300;400&family=Roboto+Slab:wght@400;700&family=Work+Sans:ital,wght@1,500&family=Ysabeau+Office:ital,wght@0,1;0,100;0,200;1,200;1,400;1,500&display=swap');

.contactus{
    /* margin: 2%; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    
}
body {
  margin: 0;
  padding: 0;
  /* background-color: rgb(116, 217, 221); */
  background-color: #e6e6fa;
  /* background-color: rgb(210, 200, 200); */
  /* background-color: #FDF0D1; */
  
}

.forms input,
.forms textarea {
  background-color: white;
  color: black;
  border-radius: 8px; 
  /* border: 1px solid #f615dc;  */
  /* padding: 8px;  */
  /* margin-bottom: 10px; */
}

.message{
  background-color: white;
  color: black;
   border-radius: 8px; 
  border: 1px solid #000000; 
  
}
.formmain{
    /* background-color: #1D2B53; */
    background-color:rgb(36, 157, 66);
    height: 100%;
    width: 100%;
    max-width: 600px; 
    padding: 30px;
    margin-bottom:5%;   
    border-radius: 8px; 
}

/* .forms{
 margin-top: 20px;
} */

.form-heading {
  /* margin-bottom: 20px; 
  text-align: center; 
  font-size: 3rem; */
}

.formspan{
  color: red;
}

@media (max-width: 768px) {
    .formmain {
      width: 80%; 
    }
    .contactus {
      margin-top: 30%;
    }
    .formsubheading{
      font-size: 1.2rem;
      /* color: white; */
      /* margin-left: 20px; */
    }
  }
  
  @media (max-width: 480px) {
    .formmain {
      width: 90%; 
    }
  }