
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Lato&family=Poppins:wght@300;400&family=Roboto+Slab:wght@400;700&family=Work+Sans:ital,wght@1,500&family=Ysabeau+Office:ital,wght@0,1;0,100;0,200;1,200;1,400;1,500&display=swap');

.home-page-textf{
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
      
}

.home-container-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1200px;
}
  
  .home-page-textf {
    margin-top: 20px;
    text-align: justify;
  }
  
  .home-page-textf h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    /* color: tomato; */
  }
  
  .home-span {
    font-weight: bold;
    color: #000000; 
  }
  .home-page-image-heading{
    font-weight: 500;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
h1{
  color:crimson;
}
/* YourComponent.css */

/* Card styles */
.home-page-images {
  width: 100%;
  margin-top: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-media {
 
  object-fit: cover; /* Ensure the image covers the entire container */
}

/* CARD HOVER */
.card-media:hover {
  transform: scale(1.1); 
}
.card {
  transition: height 0.5s ease 0.9s;
}

.card:hover {
  transform: scaleY(1.05); 
  box-shadow: 10px 0px 10px 0px rgba(29, 29, 29,0.7); 
} 



  /* Responsive styles */
  @media (max-width: 768px) {
   
    .home-page-textf {
     
      text-align: justify;
      
    }
  
    .home-page-textf h1 {
      margin-top: 50%;
      font-size: 2rem;
    }
  }
  @media (max-width: 600px) {
    .card-media {
      height: 200px; /* Adjust image height for smaller screens */
    }
  }
  
  
  